import {BufferGeometry, Color, Group, Line, LineBasicMaterial, Vector3} from 'three';
import {CreateGrid} from "@/render_utils/three_tools/creation_tools/CreateGrid";

export default class Grid {
    constructor(scene, near, far, mode="dark") {
        this.root = new Group();
        this.params = getParams(near, far, mode);
        this.mesh = new CreateGrid(this.params);
        this.axis = {
            xLine: null,
            // yLine: null,
            zLine: null
        };
        scene.add(this.root);
        this.creatAxis(far);
        this.setRoot();
    }
    setRoot() {
        this.root.name = "REAL_GRID";
        this.mesh.name = "REAL_GRID_MESH";
        this.axis.xLine.name = "REAL_GRID_X";
        // this.axis.yLine.name = "REAL_GRID_Y";
        this.axis.zLine.name = "REAL_GRID_Z";

        this.root.add(this.mesh);
        this.root.add(this.axis.xLine);
        // this.root.add(this.axis.yLine);
        this.root.add(this.axis.zLine);
    }
    creatAxis(far) {
        const opacity = 0.25; // 0.75;
        const red = 0xff0000;
        const blue = 0x0000ff;
        // const green = 0x00ff00;
        // Define the line materials
        let redMaterial = getLineMaterial(red, opacity);
        let blueMaterial = getLineMaterial(blue, opacity);
        // let greenMaterial = getLineMaterial(green, opacity);

        // Define the line geometries
        let xGeometry = new BufferGeometry().setFromPoints([ new Vector3(-far, 0, 0), new Vector3(far, 0, 0) ]);
        // let yGeometry = new BufferGeometry().setFromPoints([ new Vector3(0, -far, 0), new Vector3(0, far, 0) ]);
        let zGeometry = new BufferGeometry().setFromPoints([ new Vector3(0, 0, -far), new Vector3(0, 0, far) ]);

        // Create the lines
        let xLine = new Line(xGeometry, redMaterial);
        // let yLine = new Line(yGeometry, greenMaterial);
        let zLine = new Line(zGeometry, blueMaterial);

        xLine.position.y += 0.01;
        // yLine.position.y += 0.01;
        zLine.position.y += 0.01;

        this.axis.xLine = xLine;
        // this.axis.yLine = yLine;
        this.axis.zLine = zLine;
    }
}

function getParams(near, far, mode) {
    const spacing = near*far;
    return {
        distance: far,
        opacity: 0.05,
        size1: spacing / 10,
        size2: spacing / 100,
        color: getGridColor(mode),
    }
}

function getGridColor(mode) {
    return mode.toLowerCase() === "dark" ? new Color('white') : new Color('black');
}

function getLineMaterial(color, opacity) {
    return new LineBasicMaterial({color: color, opacity: opacity, transparent: true});
}
