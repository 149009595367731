<template>
  <transition name="slide">
    <div>
      <div
          :style="{ height: `calc(100vh - 128px)`, top: `calc(50% - (50vh - 64px))`}"
          class="fixed top-16 left-0 w-[380px] bg-gray-900 overflow-y-auto rounded-tr-lg rounded-br-lg"
          v-show="viewJobs"
      >
        <table class="w-full text-sm text-left text-white">
          <thead class="text-xs uppercase bg-gray-700 text-gray-400">
          <tr>
            <th scope="col" class="px-6 py-3">Job ID</th>
            <th scope="col" class="px-6 py-3">Status</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="job in jobs"
              :key="job.jobID"
              class="border-b bg-gray-800 border-gray-700 hover:bg-gray-600"
              @click="isDone(job) && downloadJob(job)"
              :class="{ 'cursor-pointer': isDone(job) }"
          >
            <td class="px-6 py-4">{{ job.jobID }}</td>
            <td class="px-6 py-4">
              <div class="flex items-center">
                <div
                    class="h-2.5 w-2.5 rounded-full me-2"
                    :class="getStatusClass(job)"
                ></div>
                <span>{{ getDisplayStatus(job) }}</span>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>
      <div
          :class="{
        'bg-sc-dark2 rounded-lg fixed top-1/2 left-0': !viewJobs,
        'bg-sc-dark2 rounded-lg fixed top-1/2 left-[380px]': viewJobs
      }">
        <MenuButton
            title="Render"
            style="width: 20px"
            :img="viewJobs ? icons.revArrowIcon : icons.arrowIcon"
            @click="toggleJobs"
        />
      </div>

    </div>
  </transition>
</template>


<script>
import {IconsUI} from "@/utils/IconsUI";
import MenuButton from "@/components/ui/MenuButton.vue";
import EventBus from "@/utils/EventBus";

export default {
  name: "JobUI",
  components: {MenuButton},
  props: {
    jobs: {type: Array, required: true},
    icons: {type: IconsUI, required: true},
  },
  data () {
    return {
      viewJobs: false,
    }
  },
  methods: {
    isDone(job) {
      const status = job.status;
      const result = String(job.result);
      return status === 'COMPLETED' && result.startsWith("http");
    },
    getDisplayStatus(job) {
      return this.getStatus(job);
    },
    getStatus(job) {
      const status = job.status;
      let curStatus = status.toString().toUpperCase();
      if(status === 'COMPLETED') if(!this.isDone(job)) curStatus = "FAILED";
      return curStatus;
    },
    getStatusClass(job) {
      const status = this.getStatus(job);
      const allStatuses = ['NEW', 'WAITING', 'FAILED', 'COMPLETED'];
      return {
        'bg-gray-300': status === allStatuses[0],
        'bg-gray-500': status === allStatuses[1],
        'bg-red-500': status === allStatuses[2],
        'bg-green-500': status === allStatuses[3],
        'bg-orange-300': !allStatuses.includes(status),
      };
    },
    downloadJob(job) {
      const url = job.result.toString();
      if(!url.startsWith("http")) console.error("INVALID URL");
      // window.location.href = url;
      // window.open(url, "_blank");
      const newWindow = window.open('', '_blank');
      newWindow.document.write(`
                <html lang="en">
                <head><title>Image</title></head>
                <body style="margin:0; display:flex; justify-content:center; align-items:center; height:100vh; background-color:#000;">
                    <img src="${url}" alt="Rendered Image" style="max-width:100%; max-height:100%;">
                </body>
                </html>
            `);
      newWindow.document.close();
    },
    setViewJobs(value) {
      this.viewJobs = value;
    },
    toggleJobs() {
      this.viewJobs = !this.viewJobs;
    },
  },
  beforeMount() {
    EventBus.on('viewJobs', this.setViewJobs);
  },
  beforeUnmount() {
    EventBus.off('viewJobs', this.setViewJobs);
  },
}
</script>
