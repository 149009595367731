import {Color} from "three";
import * as THREE from "three";
import * as REAL from "real_api";
import { getCurrentInstance } from 'vue'
import Grid from "@/render_utils/three_tools/Grid";
import Transform from "@/render_utils/three_tools/Transform";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {NameExtra} from "@/render_utils/three_tools/gizmos_tools/giz_tools";
import {createSunGiz} from "@/render_utils/three_tools/gizmos_tools/sun_gizmos";
import {createAreaGiz} from "@/render_utils/three_tools/gizmos_tools/area_gizmos";
import {createCameraGiz} from "@/render_utils/three_tools/gizmos_tools/camera_gizmos";
import {createBoundingBox} from "@/render_utils/three_tools/gizmos_tools/model_gizmos";
import {createSpotGiz} from "@/render_utils/three_tools/gizmos_tools/spot_gizmos";
import {createPointGiz} from "@/render_utils/three_tools/gizmos_tools/point_gizmos";


export default class Scene{
    constructor() {
        const canvas = this.getCanvas();
        this.instance = getCurrentInstance();
        const { proxy } = this.instance;
        const params = {
            powerPreference: "high-performance",
            preserveDrawingBuffer: true,
            alpha: true,
            antialias: true
        }
        if(canvas) params.canvas = canvas;

        this.params = params;
        this.background = new Color(0x202020);

        this.modelData = {
            areaLights: [],
            modelLights: [],
            modelCamera: null,
        }
        this.canRender = false;
        this.sceneRender = true;
        this.activeCamera = null;
        const near = 0.01;
        const far = 10000;
        this.scene = new THREE.Scene();
        proxy.$scene = this.scene;
        this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, near, far);
        this.renderer = new THREE.WebGLRenderer(params);
        this.ambientLight = new THREE.AmbientLight(0xffffff);
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.transform = new Transform(this.controls, this.renderer, this.camera, this.scene);
        this.grid = new Grid(this.scene, near, far);
        this.setCamera(this.camera);
        this.setScene();
        this.render();
        this.addGrid();
        NameExtra(this.ambientLight);
    }
    loadFromData(sceneData){
        try {
            return new THREE.ObjectLoader().parse( sceneData );
        }
        catch (e) {
            return {
                error: e,
                isScene: false
            }
        }
    }
    async open(buffers, setProgress, setError) {
        const realScene = await REAL.LoadScene(buffers, setProgress, setError);
        console.log(realScene);
        const scene = realScene.scene;
        const cameras = realScene.cameras;
        const sunLights = realScene.sunLights;
        const areaLights = realScene.areaLights;
        const spotLights = realScene.spotLights;
        const pointLights = realScene.pointLights;
        for (const camera of cameras) {
            camera.aspect = window.innerWidth / window.innerHeight;
            this.addCamera(camera, camera.name === "REAL_EYE");
        }
        for (const child of sunLights) this.addSunLight(child, true);
        for (const child of spotLights) this.addSpotLight(child, true);
        for (const child of pointLights) this.addPointLight(child, true);
        for (const child of areaLights) this.addAreaLight(child);
        const children = [...scene.children];
        for (const child of children) this.loadModel(child);
    }
    setControlRender(canRender){
        this.canRender = canRender;
    }
    setSceneRender(sceneRender){
        this.sceneRender = sceneRender;
    }
    getCanvas(){
        const canvases = document.querySelectorAll('canvas');
        for (const canvas of canvases) if(canvas.id === "THREE") return canvas;
    }
    setCamera(camera) {
        if(!camera) return;
        this.activeCamera = camera;
    }
    switchCamera(camera) {
        if(this.activeCamera === camera) return;
        if(isCamera(camera)) {
            this.enableCameraGiz();
            return this.setCamera(camera);
        }
        else if(camera.name !== "REAL_CAM_GIZ") return;
        const curCamera = this.enableCameraGiz(camera);
        this.setCamera(curCamera);
    }
    enableCameraGiz(child=undefined) {
        if(child) {
            this.transform.deselectPicked();
            let camera = undefined;
            for (const giz of child.children) {
                if(isCamera(giz)) camera = giz;
                else giz.visible = false;
            }
            return camera;
        }
        const camera = this.activeCamera;
        if(!camera) return;
        const gizMos = camera.parent;
        if(!gizMos) return;
        if(gizMos.name !== "REAL_CAM_GIZ") return;
        for (const giz of gizMos.children) {
            if(isCamera(giz)) continue;
            giz.visible = true;
        }
        return this.transform.setAsPicked(gizMos);
    }
    changePreview(lightMode) {
        const modelLights = this.modelData.modelLights;
        if(lightMode) {
            this.removeParent(this.ambientLight);
            for (const modelLight of modelLights) {
                this.attach(modelLight);
            }
        }
        else {
            this.attach(this.ambientLight);
            for (const modelLight of modelLights) {
                this.removeParent(modelLight);
            }
        }
    }
    addGrid() {
        if(!this.grid.mesh.parent) this.add(this.grid.mesh)
        this.cameraLook();
    }
    addCamera(camera, isDefault) {
        this.attach(camera);
        const rot = new THREE.Euler().copy(camera.rotation);
        const pos = new THREE.Vector3().copy(camera.position);
        camera.rotation.set(0, 0, 0);
        camera.position.set(0, 0, 0);
        const gizMos = createCameraGiz(camera);
        if(isDefault) {
            if(this.modelData.modelCamera) this.modelData.modelCamera.name = "CAMERA";
            camera.name = "REAL_EYE";
            this.modelData.modelCamera = camera;
        }
        else camera.name = "CAMERA";
        this.add(gizMos);
        this.addToGiz(gizMos);
        gizMos.rotation.copy(rot);
        gizMos.position.copy(pos);
        return gizMos;
    }
    addPointLight(pointLight, selfTrans=false) {
        this.attach(pointLight);
        const rot = new THREE.Euler().copy(pointLight.rotation);
        const pos = new THREE.Vector3().copy(pointLight.position);
        pointLight.rotation.set(0, 0, 0);
        pointLight.position.set(0, 0, 0);
        const giz = createPointGiz(pointLight);
        this.add(giz);
        this.addToGiz(giz);
        if(selfTrans) {
            giz.rotation.copy(rot);
            giz.position.copy(pos);
        }
        return giz;
    }
    addSpotLight(spotLight, selfTrans=false) {
        this.attach(spotLight);
        const rot = new THREE.Euler().copy(spotLight.rotation);
        const pos = new THREE.Vector3().copy(spotLight.position);
        spotLight.rotation.set(0, 0, 0);
        spotLight.position.set(0, 0, 0);
        const giz = createSpotGiz(spotLight, this.scene);
        this.add(giz);
        this.addToGiz(giz);
        if(selfTrans) {
            giz.rotation.copy(rot);
            giz.position.copy(pos);
        }
        return giz;
    }
    addSunLight(sun, selfTrans=false) {
        this.attach(sun);
        const rot = new THREE.Euler().copy(sun.rotation);
        const pos = new THREE.Vector3().copy(sun.position);
        sun.rotation.set(0, 0, 0);
        sun.position.set(0, 0, 0);
        const sunGiz = createSunGiz(sun, this.scene);
        this.addToGiz(sunGiz);
        if(selfTrans) {
            sunGiz.rotation.copy(rot);
            sunGiz.position.copy(pos);
        }
        return sunGiz;
    }
    addAreaLight(light) {
        this.attach(light);
        const rot = new THREE.Euler().copy(light.rotation);
        const pos = new THREE.Vector3().copy(light.position);
        light.rotation.set(0, 0, 0);
        light.position.set(0, 0, 0);

        this.add(light);
        const child = light.children[0];
        createAreaGiz(child);
        this.addToGiz(child);

        child.rotation.copy(rot);
        child.position.copy(pos);
    }
    addToGiz(child) {
        this.transform.addClickableObject(child);
        this.transform.setAsPicked(child);
    }
    loadModel(model){
        this.attach(model);
        this.setMat(model);
        const icon = createBoundingBox(model);
        const rot = new THREE.Euler().copy(model.rotation);
        const pos = new THREE.Vector3().copy(model.position);
        icon.add(model);
        icon.rotation.set(-rot.x, -rot.y, -rot.z);
        icon.position.set(-pos.x, -pos.y, -pos.z);
        this.add(icon);
        this.addToGiz(icon);
        return icon;
    }
    setMat(model){
        model.traverse(child => {
            const material = child.material;
            if(child.type === "Mesh" && material) {
                if(!(material.transparent && material.opacity < 1)) {
                    child.castShadow = true;
                    child.receiveShadow = true;
                }
            }
        })
    }
    traverseParent(obj) {
        let parent = obj.parent;
        while (parent !== null) {
            if (parent instanceof THREE.Scene) {
                return obj;
            }
            obj = parent;
            parent = obj.parent;
        }
        return null;
    }
    cameraLook() {
        const look = new THREE.Group()
        look.position.set(0, 0, 0)
        const target = new THREE.Vector3(2, 2.5, -5);

        this.camera.position.copy(target);
        this.camera.lookAt(look.position.x, look.position.y, look.position.z);
        this.controls.target.set(look.position.x, look.position.y, look.position.z);
        this.setControl();
        this.canRender = true;
    }
    setControl() {
        const speed = 5;
        const touchSpeed = 2; // Adjust this value for touch zoom speed
        const controls = this.controls;
        controls.mouseButtons = {
            LEFT: THREE.MOUSE.ROTATE,
            MIDDLE: THREE.MOUSE.PAN,
            // MIDDLE: THREE.MOUSE.DOLLY,
            RIGHT: THREE.MOUSE.PAN
        }
        controls.keys = {
            UP: 'ArrowUp', // up arrow
            LEFT: 'ArrowLeft', //left arrow
            RIGHT: 'ArrowRight', // right arrow
            BOTTOM: 'ArrowDown' // down arrow
        }
        controls.touches = {
            ONE: THREE.TOUCH.ROTATE,
            TWO: THREE.TOUCH.DOLLY_PAN
        }
        controls.panSpeed = speed;
        controls.zoomSpeed = speed;
        controls.touches.panSpeed = touchSpeed;
        controls.touches.zoomSpeed = touchSpeed;
    }
    add(item){
        this.scene.add(item);
    }
    attach(item){
        this.scene.attach(item);
    }
    remove(item) {
        if(item.parent === this.scene) this.scene.remove(item);
    }
    removeParent(item) {
        if(item.parent) item.parent.remove(item);
    }
    setScene(){
        const camera = this.camera;
        camera.name = "EDITOR_CAMERA";
        camera.layers.enable(1);
        camera.layers.enable(2);

        this.renderer.domElement.id = 'THREE';
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.needsUpdate = true;
        this.scene.add(this.ambientLight);

        //Environment
        this.scene.background = this.background;
        document.body.appendChild( this.renderer.domElement );
        this.onWindowResize();
        window.addEventListener('resize', this.onWindowResize.bind(this), false)
    }
    render(){
        requestAnimationFrame(this.render.bind(this));
        if(this.sceneRender) {
            this.renderer.render(this.scene, this.activeCamera);
            if (this.canRender) this.controls.update();
        }
    }
    onWindowResize() {
        const width = window.innerWidth;
        const height = window.innerHeight;
        const camera = this.activeCamera;
        const renderer = this.renderer;
        camera.aspect = width / height
        camera.updateProjectionMatrix()
        renderer.setSize(width, height)
    }
}

function isCamera(child) {
    return ["PerspectiveCamera", "OrthographicCamera"].includes(child.type);
}

